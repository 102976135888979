import type { PlayerResponsePost } from "~/types";

const {
	public: { googleMapsApi }
} = useRuntimeConfig();

const validationInputFn = ({ value }: ValidationFnArgs) => validateStringLength(value, 1);
const validationNameFn = ({ value }: ValidationFnArgs) => validateStringLength(value, 1) && validateLatinLetters(value);
const validationPhoneFn = ({ value }: ValidationFnArgs) => validateStringLength(value, 13);
const validationZipFn = ({ value }: ValidationFnArgs) => validateStringLength(value, 4);

const useUserInfo = ({
	rewardEntry = false,
	redeemForm = false
}: { rewardEntry?: boolean; redeemForm?: boolean } = {}) => {
	const { close } = useNlcModals();
	const { data: appInitData, refresh: refreshAppInitData } = useAppInitData();
	const {
		data: profileData,
		easyProfileCompleted,
		easyProfileEnabled,
		refresh: refreshProfileData,
		visibleField
	} = useCompleteProfile();

	const birthday = computed(() => {
		if (!appInitData.value?.birthDay) {
			return "";
		}
		return `${appInitData.value?.birthYear}-${String(appInitData.value?.birthMonth).padStart(2, "0")}-${String(
			appInitData.value?.birthDay
		).padStart(2, "0")}`;
	});

	const firstNameField = useInputField({
		initialValue: appInitData.value?.name || "",
		validationFn: validationNameFn
	});
	const lastNameField = useInputField({
		initialValue: appInitData.value?.surname || "",
		validationFn: validationNameFn
	});
	const addressField = useInputField({
		initialValue: appInitData.value?.address || "",
		validationFn: validationInputFn
	});
	const cityField = useInputField({
		initialValue: appInitData.value?.city || "",
		validationFn: validationInputFn
	});
	const zipCodeField = useInputField({
		initialValue: appInitData.value?.zip || "",
		validationFn: validationZipFn
	});
	const phoneField = useInputField({
		initialValue: appInitData.value?.phone || "",
		validationFn: validationPhoneFn
	});
	const secondAddressField = useInputField({
		initialValue: appInitData.value?.address2 || "",
		validationFn: validationInputFn
	});
	const middleNameField = useInputField({
		initialValue: ""
	});
	const dateField = useInputField({
		initialValue: birthday.value,
		validationFn: validationInputFn
	});
	const stateField = useInputField({
		initialValue: states[0]?.name,
		validationFn: validationInputFn
	});

	const suffix = ref("" || suffixes[0]);

	const isLoading = ref(false);

	const validationChecks = [
		firstNameField.isValid,
		lastNameField.isValid,
		phoneField.isValid,
		stateField.isValid,
		zipCodeField.isValid,
		addressField.isValid,
		cityField.isValid
	];
	const isDisabled = computed(() => !validationChecks.every((check) => check.value));

	const easyProfileValidationChecks = computed(() =>
		[firstNameField.isValid, lastNameField.isValid, zipCodeField.isValid].concat(
			visibleField("phone") ? phoneField.isValid : [],
			visibleField("address") ? addressField.isValid : [],
			visibleField("city") ? cityField.isValid : [],
			visibleField("state") ? stateField.isValid : []
		)
	);
	const isDisabledEasy = computed(() => !easyProfileValidationChecks.value?.every((check) => check.value));

	const showAddFields = computed(
		() =>
			!!cityField.value.value ||
			!!secondAddressField.value.value ||
			!!stateField.value.value ||
			!!zipCodeField.value.value ||
			addressField.value.value?.length > 2
	);

	const userDataSource = computed(() => {
		if (redeemForm) {
			return null;
		}
		if (rewardEntry) {
			return "rewards";
		}
		return "deposit";
	});

	const { execute } = useAsyncFetch({
		path: "/rest/player/profile/",
		method: "post",
		options: {
			server: false,
			immediate: false
		},
		fetchOptions: () => {
			const constructBody = () => {
				const easyProfileFields = {
					name: firstNameField.value.value,
					surname: lastNameField.value.value,
					birthday: prepareDate(dateField.value.value),
					zip: zipCodeField.value.value
				};

				if (!easyProfileEnabled.value || easyProfileCompleted.value) {
					return {
						...easyProfileFields,
						phone: preparedPhone(phoneField.value.value),
						address: addressField.value.value,
						address2: secondAddressField.value.value,
						city: cityField.value.value,
						state: appInitData.value?.state || stateField.value.value,
						source: userDataSource.value,
						name_suffix: suffix.value,
						middle_name: middleNameField.value.value,
						email: appInitData.value?.email || ""
					};
				}

				return easyProfileFields;
			};

			return {
				body: constructBody(),
				onRequest: () => {
					isLoading.value = true;
				},
				onResponse: ({ response }) => {
					isLoading.value = false;
					const { success, info, errors } = response?._data as PlayerResponsePost;

					if (success && appInitData.value) {
						refreshAppInitData();

						appInitData.value = {
							...appInitData.value,
							...filterObject(info!, ["easyProfileCompleted", "easyProfileModuleEnabled", "uncompletedProfileFields"])
						};

						if (easyProfileEnabled.value) {
							refreshProfileData();
						}

						setTimeout(() => {
							window.scrollTo({ top: 0, behavior: "smooth" });
						}, 1000);

						window.$store.gaCash.submit({
							event: "verification",
							step: "success",
							type: "personal_info",
							location: userDataSource.value
						});

						dispatchQuasarEvent("CLOSE_PROFILE", { detail: info });

						close("LazyOModalProfile");
					}

					if (!success && errors) {
						if (errors.type === "phone") {
							phoneField.errorMessage.value = errors.message;
						}
						if (errors.type === "birthday") {
							dateField.errorMessage.value = errors.message;
						}
					}
				},
				onRequestError: () => {
					isLoading.value = false;
				},
				onResponseError: () => {
					isLoading.value = false;
				}
			};
		}
	});

	const onSubmit = (event: Event) => {
		event.preventDefault();

		window.$store.gaCash.clickButton({
			location: "personal_info",
			buttonName: "submit"
		});

		execute();
	};

	window.loadScript = (src, id, isAsync, onload) => {
		const newScript = document.createElement("script");
		newScript.src = src;
		newScript.id = id;
		newScript.onload = onload || null;
		newScript.async = isAsync || false;
		document.head.appendChild(newScript);
	};

	const setAutocomplete = () => {
		const input = document.getElementById("address")?.querySelector?.("input");
		if (!input) {
			return;
		}
		const options = {
			fields: ["address_components"],
			componentRestrictions: { country: "us" }
		};
		if (!window.google?.maps) {
			console.error("Google Maps library is not loaded.");
			return;
		}
		const autocomplete = new window.google.maps.places.Autocomplete(input, options);
		autocomplete.addListener("place_changed", () => {
			const place = autocomplete.getPlace();
			addressField.value.value = "";
			for (const component of place.address_components) {
				const componentType = component.types[0];
				const componentTypeHandlers: Record<string, () => void> = {
					street_number: () => {
						addressField.value.value = `${component.long_name} `;
					},
					route: () => {
						addressField.value.value += component.long_name;
					},
					postal_code: () => {
						zipCodeField.value.value = `${component.long_name}`;
					},
					postal_code_suffix: () => {
						zipCodeField.value.value += `-${component.long_name}`;
					},
					locality: () => {
						cityField.value.value = component.long_name;
					},
					sublocality_level_1: () => {
						cityField.value.value = component.long_name;
					},
					administrative_area_level_1: () => {
						const longName = states.find((s) => s.code === component.short_name);
						stateField.value.value = longName ? longName.name : "";
					}
				};

				const handler = componentTypeHandlers[componentType];
				if (handler) {
					handler();
				}
			}
		});
	};

	const loadAutocompleteScript = (url: string) => {
		window.loadScript(url, "google-place-field", true, () => {
			setAutocomplete();
		});
	};

	onMounted(() => {
		const googleScript = document.getElementById("google-place-field");
		if (!googleScript) {
			loadAutocompleteScript(`https://maps.googleapis.com/maps/api/js?key=${googleMapsApi}&libraries=places`);
		} else {
			setAutocomplete();
		}
	});

	return {
		firstNameField,
		profileData,
		easyProfileCompleted,
		isLoading,
		lastNameField,
		addressField,
		cityField,
		zipCodeField,
		phoneField,
		secondAddressField,
		middleNameField,
		dateField,
		stateField,
		appInitData,
		showAddFields,
		suffix,
		suffixes,
		states,
		isDisabledEasy,
		isDisabled,
		visibleField,
		onSubmit
	};
};

export default useUserInfo;
